.project {
  &>div a {
    transition: 0.2s;
    filter: saturate(.1);
  }

  &:hover {
    &>div a {
      filter: none;
    }
  }
}


.socialLink {
  transition: 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
}

.bg {
  background: linear-gradient(0deg, #5e57e0 0%, rgb(14 165 233) 90%);
  filter: saturate(.8) brightness(.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}



.project {
  &:nth-child(2n+1) {
    & .projectDisplay {
      right: 0;
    }
  }

  &:nth-child(2n) {
    & .projectDisplay {
      left: 0;
    }

    &>h1 {
      @apply text-right;
    }

    & .description {
      margin-left: auto;
    }

    & .techContainer {
      float: right;
    }
  }
}

.description {
  transition: 1s ease;
  opacity: 0;
  margin-left: 300px;
  margin-right: 300px;

  &.description-show {
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
  }
}

.projectLink {
  @apply flex mt-4 gap-4 w-min h-min;

  &>svg:hover {
    stroke: white;
  }
}

.links {
  @apply flex gap-4;
}

.header {
  transition: 300ms;
  filter: saturate(.8) brightness(.8);

  // & li:hover {
  //   color: rgb(79 70 229);
  // }

  &.hide {
    transform: scaleY(0);
    transform-origin: 0 0;
    opacity: 0;
  }
}

#tracker li {
  transition: 300ms;
  position: relative;


  &.active {
    color: white;

    //use trackerAnimation
    animation: trackerAnimation 500ms ease-in-out;
  }
}

@keyframes trackerAnimation {
  25% {
    transform: rotate3d(0, 1, 0, 0deg);
    filter: drop-shadow(1px 1px 4px white);
  }

  50% {
    transform: rotate3d(0, 1, 0, 90deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

.glow {
  &:hover {
    color: #4f46e5;
    text-shadow: 0 0 5px #4f46e5, 0 0 25px #4f46e5, 0 0 50px #4f46e5, 0 0 200px #4f46e5;
  }
}